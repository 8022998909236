import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import {  Row } from "antd";
import ImpactActionRow from "../../components/Items/ImpactActionRow";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import TitleV2Button from "../../components/Button/TitleV2Button";
import ActionButton from "../../components/Button/ActionButton";
import { LeftCircleFilled } from "@ant-design/icons";
import EnvironmentalMonitoringCanvas from "./Model5";
import LivestockHealthCanvas from "./Model6";
import AgricultureCanvas from "./Model4";
import PrecisionAgricultureCanvas from "./Model3";

import HealthMonitoringCanvas from "./Model2";
import DroneSolutionsCanvas from "./Model1";


export default function BusinessModelCanva() {
  const navigate = useNavigate();
  const [service,setService] = useState(null);
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");
    setService(localStorage.getItem("currentService"));

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

 
  const handleSaveButtonClick = () => {
    // Your save button click logic here
    console.log("Save button clicked!");
    navigate(-1);
  };
  

  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ActionButton
            buttonText="Back"
            icon={<LeftCircleFilled style={{ fontSize: '16px', color: '#fff' }} />}
           onClick={handleSaveButtonClick}
          />
            <TitleV2Button
              icon={<EvaluateIcon size={"40px"} />}
              buttonText="Results: "
              secondText="Business Model Canvas"
              isActive={true}
            />
            <div style={{ marginTop: "20px", display: "flex",
              justifyContent:"space-between",
              alignItems: "space-between",
              flexDirection: "row", }}>
           
              <ImpactActionRow />
            </div>
          </div>
        }
      >
        
        <Row
          style={{ width: "85vw", marginTop: "00px",minHeight:'80vh' }}
          gutter={[16, 16]}
         
          
        >
           <Row style={{width:'100%',minHeight:'70vh'}}>
         
          {service==1?<EnvironmentalMonitoringCanvas/>:<></>}
          {service==7?<LivestockHealthCanvas/>:<></>}
          {service==8?<AgricultureCanvas/>:<></>}
          {service==3?<PrecisionAgricultureCanvas/>:<></>}
          {service==10?<HealthMonitoringCanvas/>:<></>}
          {service==9?<DroneSolutionsCanvas/>:<></>}
               
            </Row>
            
           
         
        
        </Row>

        
      </AppContent>
    </HomeLayout>
  );
}
