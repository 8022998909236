import React from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const LevelAssessmentButton = ({ buttonText,isActive,height,handleClick }) => {
  const buttonStyle = {
    width: '50%',
    height: height??'60px',
    //flexShrink: 0,
    
    backgroundColor: isActive?'#00678A':'#B5B5B5',
    //filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //margin:'10px',
    borderRadius: '15px'
  };
  const { Title } = Typography;

  return (
    <Button style={buttonStyle}  onClick={handleClick} >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  }}>
       
       
          <Title level={4} style={{marginLeft:'10px' ,color: '#FFF', fontStyle: 'normal', fontWeight: '700',textAlign:'center',marginTop:'5px' }}>
            {buttonText}
          </Title>
        
      </div>
    </Button>
  );
};

LevelAssessmentButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  
  isActive: PropTypes.bool,
  height:PropTypes.string,
  handleClick: PropTypes.func
};

export default LevelAssessmentButton;
