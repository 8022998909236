import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;



const CountrySelect = ({ onSelect, defaultValue ,countries}) => {
  return (
    <Select
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onSelect}
    >
         <Option value="">Select</Option>
         {countries.map(country => (
      <Option key={country.code} value={country.code}>
        {country.name}
      </Option>
    ))}
    </Select>
  );
};
CountrySelect.propTypes = {
    onSelect: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
    countries: PropTypes.array
  };
export default CountrySelect;
