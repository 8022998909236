// routes.js

const paths = {
    INTRO: '/',
    HOME: '/home',
    PROFILE: '/profile',
    USER_PROFILE: '/profile/:userId', 
    Location_DETAILS:{
        INDEX:"/location-details"
    },
    SERVICE:{
        NORMAL_VIEW:'/service-requirements/?view=normal',
        EXPERT_VIEW:'/service-requirements/expert'
    },
    IMPACT_ASSESSMENT:{
        INDEX:'/impact-assessment'

    },
    RESULT:{
        SUGGESTED_TECHNOLOGY:'/suggested-technology'

    },
    SUB_RESULTS:{
        TECHNO_ECONOMIC:'/techno-economic-assessment',
        SOCIO_ENVIRONMENT:'/socio-environment-assessment',
        BUSINESS_MODEL_CANVA : '/business-model-canva'

    },
    SUMMARY:{
        SUMMARY_RESULTS_PAGE:'/summary-result'

    },
        // Dynamic segment for user profile with parameter userId
    SECTOR_SEVICES:{
        INDEX: '/sector-services',
        STEP02: '/sector-services-02',
        SECTOR: '/sector-services/:sectorId',
        SERVICE: '/sector-services/:sectorId/:serviceId',
    }
    // ... add more routes as needed
  };
  
  export default paths;
  