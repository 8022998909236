// api.js

import axios from 'axios';
import jsonObject from '../utility/jsonObject';
import { message } from 'antd';


/**
 * Sends a POST request to a predefined API endpoint with a JSON payload and bearer token authentication.
 * 
 * @returns {Promise<Object>} A promise that resolves with the API response data.
 * @throws {Error} If the API request fails.
 */
export const postDataICCS = async () => {
  const url = 'http://xgain.iccs.gr:8000/main';
  const jsonBody = jsonObject();
  const bearerToken = localStorage.getItem('access_token') ;

  try {
    const response = await axios.post(url, jsonBody, {
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      }
    });
    localStorage.setItem('iccs_body');
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(`API error: ${error.response.status} - ${error.response.data}`);
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(`Error setting up the request: ${error.message}`);
    }
  }
};

const USERNAME = 'xgain';
const PASSWORD = 'xG4iN1Cc$-ins';

export const postDataToICCSApi = async () => {
    const loginData = new URLSearchParams();
    loginData.append('grant_type', '');
    loginData.append('username', USERNAME);
    loginData.append('password', PASSWORD);
    loginData.append('scope', '');
    loginData.append('client_id', '');
    loginData.append('client_secret', '');

    try {
        const loginResponse = await fetch("/api/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
            body: loginData
        });

        if (!loginResponse.ok) {
            throw new Error(`HTTP error! status: ${loginResponse.status}`);
        }

        const loginResponseData = await loginResponse.json();
        localStorage.setItem('access_token', loginResponseData.access_token);
        console.log('Login response:', loginResponseData);


        const mainData =JSON.parse( localStorage.getItem('iccs_body_request'));
        console.log(mainData);
        // Now post the JSON data to /main
        const noData = {};
        /*const mainData = {
          
            "sector": {
              "type": "text",
              "result": "Aquaculture"
            },
            "service": {
              "type": "text",
              "result": "Remote Farming"
            },
            "user_type_selection": {
              "type": "text",
              "result": "End-User"
            },
            "level_of_assessment": {
              "type": "text",
              "result": "Local"
            },
            "location": {
              "type": "text",
              "result": "BE"
            },
            "Area": {
              "type": "number",
              "result": "12"
            },
            "Vegetation_height": {
              "type": "string",
              "result": 0
            },
            "Terrain": {
              "type": "list",
              "result": [
                1,
                0,
                0,
                0,
                0
              ]
            },
            "Weather": {
              "type": "string",
              "result": [
                1,
                0,
                0,
                0,
                0
              ]
            },
            "dev_per_type": {
              "type": "list",
              "result": [
                "12",
                0,
                0,
                0,
                0
              ]
            },
            "sensor_rate": {
              "type": "list",
              "result": [
                1,
                0
              ]
            },
            "sensor_freq": {
              "type": "list",
              "result": [
                1,
                0,
                0,
                0
              ]
            },
            "sensor_lat": {
              "type": "list",
              "result": [
                1,
                0
              ]
            },
            "type_of_drones": {
              "type": "string",
              "result": [
                0
              ]
            },
            "drone_service": {
              "type": "string",
              "result": [
                0
              ]
            },
            "drone_altitude": {
              "type": "string",
              "result": [
                0
              ]
            },
            "drone_image_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "drone_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "drone_iot_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "personal_dev_type": {
              "type": "string",
              "result": [
                0
              ]
            },
            "tablet_internet": {
              "type": "string",
              "result": [
                0
              ]
            },
            "tablet_image_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "tablet_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "tablet_rate_down": {
              "type": "string",
              "result": [
                0
              ]
            },
            "laptop_internet": {
              "type": "string",
              "result": [
                0
              ]
            },
            "laptop_image_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "laptop_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "laptop_rate_down": {
              "type": "string",
              "result": [
                0
              ]
            },
            "personal_internet": {
              "type": "string",
              "result": [
                0
              ]
            },
            "personal_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "personal_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "personal_rate_down": {
              "type": "string",
              "result": [
                0
              ]
            },
            "camera_rate": {
              "type": "string",
              "result": [
                0
              ]
            },
            "camera_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "camera_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "camera_image_freq": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_type": {
              "type": "string",
              "result": "string"
            },
            "robot_service": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_image_rate": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_image_freq": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_image_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_image_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_power": {
              "type": "string",
              "result": 0
            },
            "robot_cost": {
              "type": "string",
              "result": 0
            },
            "robot_iot_rate": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_iot_freq": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_iot_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_iot_lat": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_speech_freq": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_speech_proc": {
              "type": "string",
              "result": [
                0
              ]
            },
            "robot_speech_lat": {
              "type": "string",
              "result": [
                0
              ]
            }
          
        };*/
        localStorage.setItem('iccs_response',JSON.stringify(noData));
        const mainResponse = await fetch("/api/main", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginResponseData.access_token}`
            },
            body: JSON.stringify(mainData)
        });

        if (!mainResponse.ok) {
            throw new Error(`HTTP error! status: ${mainResponse.status}`);
        }

        const mainResponseData = await mainResponse.json();
        console.log('Main response:', mainResponseData);
        localStorage.setItem('iccs_response',JSON.stringify(mainResponseData));

        return true;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        //throw error;
       // message('Operation in progress...', 0);
        message.error("There was a problem with the fetch operation");

        return false;
    }
};