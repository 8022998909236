import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';



const ServiceItem = ({  text, checked, onChange,isActive }) => {
  return (
    <div
      style={{
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        padding: '8px',
       // border: '1px solid #ccc',
        marginBottom: '8px',
        borderRadius: '4px',
        backgroundColor:'rgba(234, 234, 234, 0.56)'
      }}
    >
         <Radio checked={checked} onChange={onChange} disabled={!isActive??true}  />
      <div style={{ display: 'flex', alignItems: 'end' }}>
       
        <span style={{color:"black",marginLeft:'10px',fontWeight:'400',lineHeight:'15px',fontSize:'18px'}}>{text}</span>
      </div>
     
    </div>
  );
};

ServiceItem.propTypes = {
  
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default ServiceItem;