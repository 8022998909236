import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const LanguageRadio = ({ value, label, imageUrl }) => (
  <Radio style={{ color: '#FFF', fontSize: '15px' }} value={value}>
    <img src={imageUrl} alt={`Language flag for ${label}`} style={{ width: '25px', height: '15px' }} />
    {label}
  </Radio>
);

export const LanguageRadioGroup = (languageOptions,onRadioChange) => {
  return (
    <Radio.Group  onChange={onRadioChange} style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'start',borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.50)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30)',
    padding: '10px', }}>
      {languageOptions.map((language) => (
        <LanguageRadio key={language.value} {...language} />
      ))}
    </Radio.Group>
  );
};

LanguageRadio.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};