import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Col, Input, Row,message, Modal ,Spin ,Button,Card,Collapse  } from "antd";
import TitleButton from "../../components/Button/TitleButton";

import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import ServiceCard from "../../components/Cards/ServiceCard";
import QuestionCard from "../../components/Cards/QuestionCard";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentQuestion, selectQuestions, toggleQuestion,selectIsEndOfQuestion, resetQuestion } from "../../reducers/questionSlice";
import PreviousButton from "../../components/Button/PreviousButton";
import NextButton from "../../components/Button/NextButton";

import { CheckCircleOutlined } from '@ant-design/icons';

import jsonObject from "../../utility/jsonObject";
import { postDataToICCSApi } from "../../services/iccs_service_main";


export default function ServiceRequirementNormalView() {


  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    //const jsonDataValues = jsonData();
    //dispatch(updateApiBody(jsonDataValues));
    
  }, []); 

  const handleButtonClick = async () =>  {
   
    setIsLoading(true);

    // Show the ongoing operation alert
    const ongoingAlert = message.loading('Operation in progress...', 0);
   // const iccs_body = jsonObject();
    //console.log(iccs_body);
  const  isSuccess = await  postDataToICCSApi();
  setIsModalVisible(isSuccess);
    // Set a timeout to show the success alert after 30 seconds
    const timer = setTimeout(() => {
      ongoingAlert(); // Close the ongoing operation alert
      setIsLoading(false);
      navigate(paths.IMPACT_ASSESSMENT.INDEX);
      
    }, 3000);

    // Clean up the timer if the component unmounts before the timer finishes
    return () => {
      clearTimeout(timer);
      ongoingAlert(); // Close the ongoing operation alert
    };
  };

  const closeModal = () => {
    setIsModalVisible(false);
    navigate(paths.IMPACT_ASSESSMENT.INDEX);
  };
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");
  

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const dispatch = useDispatch();
  const questions = useSelector(selectQuestions);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const isEndOfQuestion = useSelector(selectIsEndOfQuestion);
  //const ApiBody = useSelector(selectApiBody);


  const [selectedChoice, setSelectedChoice] = useState(null); // State to track the selected choice

  // Handler function to handle choice selection
  const handleChoice = (choice,e) => {

     // Prevent the default behavior
  e.preventDefault();
  
  // Stop event propagation
  e.stopPropagation();
   const questionKey = 'start';
   
   dispatch(toggleQuestion({ choice,questionKey }));


    setSelectedChoice(choice);
    //setQuestionFlow;
    console.log(selectedChoice);

    // You can perform additional actions based on the selected choice here
  };


  const handleConfirmButtonClick = () => {
    handleButtonClick();
    //navigate(paths.IMPACT_ASSESSMENT.INDEX);
  };

  const handleRestartButtonClick = () => {
    dispatch(resetQuestion({}));
  };
  const handleNextButtonClick = (value) => {
    const isInput = true;
    dispatch(toggleQuestion({ currentQuestion,isInput,value }));
    //const jsonDataValues = jsonData();
    //dispatch(updateApiBody(jsonDataValues));
  };
  const [inputValue, setInputValue] = useState('');

  const handleChange = (value) => {
    setInputValue(value);
  };

  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
            <div> <TitleButton
            icon={<ServiceIcon size={"40px"} />}
            buttonText="Service Requirements"
            isActive={true}
          /> 
          
          </div>
        }
      >
       
        <Row
          style={{ width: "70vw" }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col
            span={ 24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {questions[currentQuestion.key].choices.length>0?<QuestionCard questionEntry={questions[currentQuestion.key]} handleChoice={handleChoice} />:""}
            
      { questions[currentQuestion.key].input ? (
        <div  style={{ textAlign: "center",marginTop:"50px" }}>
          <ServiceCard
            title={questions[currentQuestion.key].input.label}
            items={[]}
            child={
              <div style={{ textAlign: "center",marginTop:"10px" }}>
                <Input
                 defaultValue={questions[currentQuestion.key].input.value }
                onChange={(e) => handleChange(e.target.value,e)}
                  placeholder={questions[currentQuestion.key].input.label}
                  style={{ width: "100%", marginBottom: "8px", }}
                />
                <div style={{ color: "#1D1D1D", fontSize: "20px", fontWeight: "700" }}>
                  <NextButton  isActive={true}
            handleClick={() => handleNextButtonClick(inputValue)}/>
                 
                </div>
              </div>
            }
          />
        </div>
      ) : null}
         
          </Col>
          <Col
    span={24}
    style={{ display: "flex", flexDirection: "column" }}
  >

    
    <Collapse defaultActiveKey={['1']}>
      <Panel header="JSON VISUALIZATION" key="1">
        <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
          <div>
          <pre>{JSON.stringify(jsonObject(), null, 2)}</pre>
          </div>
        </Card>
      </Panel>
    </Collapse>
    </Col>
         
          <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" type="primary" onClick={closeModal}>
            Close
          </Button>,
        ]}
        centered
        width={400}
        wrapClassName="custom-modal-wrap"
      >
        <div className="modal-content">
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <CheckCircleOutlined className="success-icon" />
              <h2>Operation Completed Successfully!</h2>
              <p>The operation has been completed successfully.</p>
            </>
          )}
        </div>
      </Modal>
          
    
        </Row>

        <Row>
          <Col span={12}>
          {currentQuestion.key!='start' || isEndOfQuestion?<PreviousButton
            isActive={true}
            handleClick={() => handleRestartButtonClick()}
          />:""}
          </Col>
          <Col span={12}>
          <ConfirmButton
            isActive={isEndOfQuestion}
            loading={isLoading}
            handleClick={() => handleConfirmButtonClick()}
          />
          </Col>
         
        
          
        </Row>
        
        
        
      </AppContent>
    </HomeLayout>
  );
}
