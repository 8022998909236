import React from 'react';
import { Modal, Button, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CustomModal = ({
  isVisible,
  onClose,
  isLoading,
  title = 'Operation Completed Successfully!',
  message = 'The operation has been completed successfully.',
  width = 400,
  closeButtonText = 'Close'
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          {closeButtonText}
        </Button>,
      ]}
      centered
      width={width}
      wrapClassName="custom-modal-wrap"
    >
      <div className="modal-content">
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <>
            <CheckCircleOutlined className="success-icon" />
            <h2>{title}</h2>
            <p>{message}</p>
          </>
        )}
      </div>
    </Modal>
  );
};


CustomModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    width: PropTypes.number,
    closeButtonText: PropTypes.string
  };
  
  CustomModal.defaultProps = {
    title: 'Operation Completed Successfully!',
    message: 'The operation has been completed successfully.',
    width: 400,
    closeButtonText: 'Close'
  };
export default CustomModal;