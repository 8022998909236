import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import DIcon from '../Icons/DIcon';


const SectorItem = ({ icon, text, checked, onChange,isActive }) => {
  return (
    <div
      style={{
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
        border: '1px solid #ccc',
        marginBottom: '8px',
        borderRadius: '4px',
        backgroundColor:isActive?'rgba(0, 103, 138, 0.89)':'rgba(0, 103, 138, 0.33)'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && <DIcon iconName={icon} style={{ marginRight: '8px' }} />}
        <span style={{color:"white",marginLeft:'10px',fontWeight:'700',lineHeight:'15px',fontSize:'18px'}}>{text}</span>
      </div>
      <Radio checked={checked} onChange={onChange} disabled={!isActive??true} />
    </div>
  );
};

SectorItem.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default SectorItem;