import React from 'react';
import { Card,Col, Row } from 'antd';

import PropTypes from 'prop-types';
import GreenSlider from "../../components/Sliders/GreenSlider";

 // Adjust the path as needed

const ImpactCard = ({ title }) => {
   
  return (
    <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
      <div>
        <div
          style={{
            color: "#00678A",
            fontSize: "20px",
            fontWeight: "700",
            marginBottom: "20px",
          }}
        >
         {title}
        </div>
        <Row>
        <Col span={8} style={{padding:'20px',marginTop:'10px'}}> <GreenSlider name="Technological"/></Col>
        <Col span={8} style={{padding:'20px',marginTop:'10px'}}> <GreenSlider name="Economic"/></Col>
        <Col span={8} style={{padding:'20px',marginTop:'10px'}}> <GreenSlider name="Environmental"/></Col>
        </Row>
       





       
      </div>
    </Card>
  );
};

ImpactCard.propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
      })
    ).isRequired,
   
    title: PropTypes.string
  };
export default ImpactCard;
