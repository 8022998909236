import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row,Collapse } from "antd";
import TitleButton from "../../components/Button/TitleButton";

import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import LocationIcon from "../../components/Icons/Location";
import AreaInput from "../../components/Inputs/AreaInput";
import CardText from "../../components/Texts/CardText";
import CountrySelect from "../../components/Inputs/CountrySelect";
import DynamicCheckCard from "../../components/Cards/DynamicCheckCard";
import DistanceInput from "../../components/Inputs/DistanceInput";

import { toggleWeatherCondition, selectWeatherConditions, selectJsonWeather, selectIsAnyWeatherChecked } from "../../reducers/weatherSlice"
import { isForestChecked, selectIsAnyTerrainChecked, selectJsonTerrain, selectTerrainTypes, toggleTerrainType } from "../../reducers/terrainSlice"
import { selectCountry, setSelectedCountry } from "../../reducers/countrySlice";

import { useDispatch, useSelector } from 'react-redux';
import { selectAreaSize, setAreaSize } from "../../reducers/areaSlice";
import { selectJsonVegetationHeight, selectVegetationHeight, setVegetationHeight } from "../../reducers/vegetationSlice";
//import { selectCountries} from "../../reducers/countrySlice";
import '../../jsonFormatter.css'

export default function LocationScreen01() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const dispatch = useDispatch();
  const weatherConditions = useSelector(selectWeatherConditions);
  const sCountry = useSelector(selectCountry);
  //useSelector(selectVal);
  const { Panel } = Collapse;
  const handleWeatherItemClick = (id) => {
    dispatch(toggleWeatherCondition({ id }));
  };
  const terrainTypes = useSelector(selectTerrainTypes);
  const countries = useSelector((state) => state.countries.data);
  const JSONCountry = useSelector((state) => state.countries.jsonCountry);
  const JSONAreaSize = useSelector((state) => state.area.jsonAreaSize);
  const JSONVegetationHeight = useSelector(selectJsonVegetationHeight);
  const JSONTerrain = useSelector(selectJsonTerrain);
  const JSONWeather = useSelector(selectJsonWeather);
  const areaSize = useSelector(selectAreaSize);
  const vegetationHeight = useSelector(selectVegetationHeight);

  const isAnyWeatherChecked = useSelector(selectIsAnyWeatherChecked);
  const isAnyTerrainChecked = useSelector(selectIsAnyTerrainChecked);

  const JSONSector = useSelector((state) => state.sector.jsonSector);
  const JSONService = useSelector((state) => state.sector.jsonService);
  const JSONUserType = useSelector((state) => state.level.jsonUserType);
  const JSONLevel = useSelector((state) => state.level.jsonLevel);

  const handleTerrainItemClick = (id) => {
    dispatch(toggleTerrainType({ id }));
    //setForestChecked( isForestChecked());
  };


  const forestChecked = useSelector(isForestChecked);

  const handleCountryChange = (value) => {
    dispatch(setSelectedCountry({ value }));


  };


  const [checkedItems, setCheckedItems] = useState({});

  const handleConfirmButtonClick = () => {

    // navigate(paths.SERVICE.NORMAL_VIEW);
    localStorage.setItem("step03", true);
    navigate(paths.SERVICE.NORMAL_VIEW);


  };
  const handleAreaSizeChange = (value) => {


    dispatch(setAreaSize({ value }));


  };
  const handleVegetationHeightChange = (value) => {


    dispatch(setVegetationHeight({ value }));


  };
  const formValid = () => {
    if (sCountry != '' && areaSize != '' && isAnyWeatherChecked && isAnyTerrainChecked) {
      return true;
    }
    return false;
  }


  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <TitleButton
            icon={<LocationIcon size={"40px"} />}
            buttonText="Location Details"
            isActive={true}
          />
        }
      >
        <Row style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={6}>
            <Row
              style={{ width: "100%", marginBottom: "30px" }}
              gutter={[16, 16]}
            >
              <Col
                span={24}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Country</CardText>
                  <div style={{ marginTop: "0px" }}>
                    <CountrySelect onSelect={handleCountryChange} defaultValue={sCountry} countries={countries} />
                  </div>
                </Card>
              </Col>
              <Col
                span={24}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Area “A” Size</CardText>
                  <div>
                    <AreaInput value={areaSize} onChange={handleAreaSizeChange} />
                  </div>
                </Card>
              </Col>
              <Col
                span={24}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {forestChecked ? <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Vegetation Height</CardText>
                  <div>
                    <DistanceInput value={vegetationHeight} onChange={handleVegetationHeightChange} />
                  </div>
                </Card> : ''}
               
              </Col>
            </Row>




          </Col>
          <Col span={9}>

            <Col
              span={24}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                <CardText>Terrain Type</CardText>
                <div style={{ marginTop: "0px" }}>

                  <DynamicCheckCard
                    title={
                      " Please choose the option that better describes the terrain type of your location:"
                    }
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    checkboxColumns={1}
                    items={terrainTypes}
                    handleItemClick={handleTerrainItemClick}
                  />

                </div>
              </Card>
            </Col>

          </Col>
          <Col
            span={9}
          // style={{ display: "flex", flexDirection: "column" }}
          >
            <Col
              span={24}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                <CardText>Typical Weather Conditions</CardText>
                <div style={{ marginTop: "0px" }}>
                  <DynamicCheckCard
                    title={
                      " Please choose the options that better describe the weather conditions at your location:"
                    }
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    checkboxColumns={1}
                    items={weatherConditions}
                    handleItemClick={handleWeatherItemClick}
                  />
                </div>
              </Card>
            </Col>
          </Col>
          <Col
                span={24}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Collapse defaultActiveKey={forestChecked ? ['1'] : []}>
    <Panel header="JSON VISUALIZATION" key="1">
    <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                 
                  <div>
                  {JSONLevel ? (
                      <pre className="json-formatter">{JSON.stringify(JSONLevel, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                  {JSONUserType ? (
                      <pre>{JSON.stringify(JSONUserType, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                  {JSONSector ? (
                      <pre>{JSON.stringify(JSONSector, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    {JSONService ? (
                      <pre>{JSON.stringify(JSONService, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    
                    {JSONCountry ? (
                      <pre>{JSON.stringify(JSONCountry, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    {JSONAreaSize ? (
                      <pre>{JSON.stringify(JSONAreaSize, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    {JSONTerrain ? (
                      <pre className="json-formatter">{JSON.stringify(JSONTerrain, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    {JSONVegetationHeight ? (
                      <pre>{JSON.stringify(JSONVegetationHeight, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                    {JSONWeather ? (
                      <pre>{JSON.stringify(JSONWeather, null, 2)}</pre>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </Card>
    </Panel>
               
                </Collapse>
              </Col>

        </Row>
        <ConfirmButton
          isActive={formValid()}
          handleClick={() => handleConfirmButtonClick()}
        />
      </AppContent>
    </HomeLayout>
  );
}
