// TerrainSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

export const terrainSlice = createSlice({
  name: 'terrain',
  initialState: {
    terrainTypes: [
      { id: 1, text: "Plain", checked: false, color: "#6C9D60", isActive: true },
      { id: 2, text: "Forest", checked: false, color: "#145216", isActive: true },
      { id: 3, text: "Mountain", checked: false, color: "#774B23", isActive: true },
      { id: 4, text: "Sea", checked: false, color: "#2359AB", isActive: true },
      { id: 5, text: "River", checked: false, color: "#239AB5", isActive: true },
    ],
    isForestChecked: false,
    jsonTerrain: {},
    isAnyTerrainChecked: false
  },
  reducers: {

    toggleTerrainType: (state, action) => {
      const { id } = action.payload;
      const terrainItem = state.terrainTypes.find(item => item.id === id);
      if (terrainItem) {
        terrainItem.checked = !terrainItem.checked;
      }
      const plainChecked = state.terrainTypes.find(item => item.text === "Plain")?.checked || false;
      const mountainChecked = state.terrainTypes.find(item => item.text === "Mountain")?.checked || false;
      if (plainChecked && mountainChecked) {
        // If both are checked, revert the selection of the last clicked item
        terrainItem.checked = !terrainItem.checked;
        message.error("You cannot select both Plain and Mountain at the same time.");
      }
      const forestItem = state.terrainTypes.find(item => item.text === "Forest");
      console.log(forestItem);
      state.isForestChecked = forestItem.checked;

      state.isAnyTerrainChecked =  state.terrainTypes.some(condition => condition.checked);
              console.log(state.isAnyTerrainChecked);

      const selectedTerrain = state.terrainTypes.map(item => item.checked ? 1 : 0);
      state.jsonTerrain = {
      
          type: "list",
          result: selectedTerrain
        
      };
    },
  },
});

export const { toggleTerrainType } = terrainSlice.actions;

export const selectTerrainTypes = state => state.terrain.terrainTypes;
export const selectJsonTerrain = state => state.terrain.jsonTerrain;
export const isForestChecked = state => state.terrain.isForestChecked
export const selectIsAnyTerrainChecked = state => state.terrain.isAnyTerrainChecked;
export default terrainSlice.reducer;
