import React from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const MenuButton = ({ buttonText, icon,isActive,height,handleClick }) => {
  const buttonStyle = {
    width: '657px',
    height: height??'80px',
    flexShrink: 0,
    
    backgroundColor: isActive? '#00678A':'rgba(0, 162, 123, 0.46)',
    //filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin:'10px'
  };
  const { Title } = Typography;

  return (
    <Button style={buttonStyle} icon={icon} onClick={handleClick} disabled={!isActive??true}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
       
       
          <Title level={2} style={{ color: '#FFF', fontStyle: 'normal', fontWeight: '700',textAlign:'center',marginTop:'5px' }}>
            {buttonText}
          </Title>
        
      </div>
    </Button>
  );
};

MenuButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  height:PropTypes.string,
  handleClick: PropTypes.func
};

export default MenuButton;
