import { Image } from 'antd';
import React from 'react';

const ServiceIcon = () => {
  return (
    <Image src='/images/icons/service.png' height={'30px'} style={{marginTop:'-5px'}}/>
  );
};


export default ServiceIcon;
