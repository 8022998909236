import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import NotFound from './components/Errors/NotFound';
import IntroScreen from './pages/Home/IntroScreen';
import HomeScreen from './pages/Home/HomeScreen';
import SectorServiceScreen from './pages/Home/SectorServiceScreen';
import SectorServiceScreen02 from './pages/Home/SectorServiceScreen02';
import LocationScreen01 from './pages/Location/LocationScreen01';
import ServiceRequirementNormalView from './pages/Service/ServiceRequirementNormalView';
import ImpactAssessment from './pages/Impact/ImpactAssessment';
import ServiceRequirementExpertView from './pages/Service/ServiceRequirementExpertView';
import paths from './routes/route';
import SuggestedTechnology from './pages/Result/SuggestedTechnology';
import SummaryResultPage from './pages/Summary/SummaryResultPage';
import SubResultTechno from './pages/Sub/SubResultTechno';
import SubResultSocio from './pages/Sub/SubResultSocio';
import BusinessModelCanva from './pages/Sub/BusinessModelCanva';


const AppRouter = () => {
  return (
    <Router>
       <Routes>
        <Route path="/" element={<IntroScreen />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/sector-services" element={<SectorServiceScreen />} />
        <Route path="/sector-services-02" element={<SectorServiceScreen02 />} />

        <Route path="/location-details" element={<LocationScreen01 />} />
        <Route path="/service-requirements" element={<ServiceRequirementNormalView />} />
        <Route path="/service-requirements/expert" element={<ServiceRequirementExpertView />} />
        <Route path="/impact-assessment" element={<ImpactAssessment />} />
        <Route path={paths.RESULT.SUGGESTED_TECHNOLOGY} element={<SuggestedTechnology />} />
        <Route path={paths.SUMMARY.SUMMARY_RESULTS_PAGE} element={<SummaryResultPage />} />
        <Route path={paths.SUB_RESULTS.TECHNO_ECONOMIC} element={<SubResultTechno />} />
        <Route path={paths.SUB_RESULTS.SOCIO_ENVIRONMENT} element={<SubResultSocio />} />
        <Route path={paths.SUB_RESULTS.BUSINESS_MODEL_CANVA} element={<BusinessModelCanva />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
