// sectorSlice.js 

import { createSlice } from '@reduxjs/toolkit';

// Define the name of the slice for easier identification within the Redux store
export const sectorSlice = createSlice({
  name: 'sector',
  initialState: {
    // Array of objects representing the different  sectors
    sectors: [
        { id: 1, text: "Aquaculture", checked: false, icon: "fish-food.png" ,isActive:true},
        { id: 2, text: "Agriculture", checked: false, icon: "agriculture.svg",isActive:true },
        { id: 3, text: "Forestry", checked: false, icon: "foresty.png",isActive:true },
        {
          id: 4,
          text: "Environmental monitoring",
          checked: false,
          icon: "leaf.png",
          isActive:true
        },
        { id: 5, text: "eHealth", checked: false, icon: "heart-health.png",isActive:true },
        { id: 6, text: "eGovernance", checked: false, icon: "museum.png" ,isActive:true},
        { id: 7, text: "Tourism", checked: false, icon: "agriculture.svg" ,isActive:true},
        { id: 8, text: "Education", checked: false, icon: "school.png" ,isActive:true},
    ],
    // Object containing user types and their associations with assessment sectors
    services: {
      data: [
        { id: 1, text: "Water Quality Monitoring", checked: false, icon: "water.png" },
        { id: 2, text: "Remote Farming", checked: false, icon: "agriculture.svg" },
        { id: 3, text: "Smart Farming", checked: false, icon: "barley.png" },
        {
          id: 4,
          text: "Precision Agriculture",
          checked: false,
          icon: "grass.png",
        },
        { id: 5, text: "Farm management", checked: false, icon: "fence.png" },
        { id: 6, text: "Land Use / Land Cover", checked: false, icon: "field.png" },
        { id: 7, text: "Livestock health", checked: false, icon: "agriculture.svg" },
        { id: 8, text: "Forest Management", checked: false, icon: "forest1.png" },
        { id: 9, text: "Drones Operation", checked: false, icon: "grone.svg" ,isActive:false},
        { id: 10, text: "Health Monitoring", checked: false, icon: "medical-doctor.png",isActive:false },
        { id: 11, text: "Government e-Services", checked: false, icon: "department.png",isActive:false },
        {
          id: 12,
          text: "Leisure",
          checked: false,
          icon: "leisure.svg",
        },
        { id: 13, text: "Distance Learning", checked: false, icon: "book-and-pencil.png" },
        { id: 14, text: "Broadband Connectivity (Access)", checked: false, icon: "public.svg" },
        { id: 15, text: "High data rate services", checked: false, icon: "frame.svg" },
      ],
      Agriculture: [2,3,4,9,5,7,14], // Service IDs associated with Agriculture
      Aquaculture: [1,2,14], // Service IDs associated with Aquaculture
      Education: [13, 14, 15], // Service IDs associated with Education
      eGovernance: [11, 6, 14], // Service IDs associated with eGovernance
      eHealth: [10, 14], // Service IDs associated with eHealth
      Forestry: [8, 14], // Service IDs associated with Foresty
      Tourism: [12, 14], // Service IDs associated with Tourism
      Environmental_monitoring: [1,6, 14], // User IDs associated with Regional sector
    },
    // Object to store user type selection data in JSON format
    jsonService: {
       "type": "string",
            "result":""
    },
    jsonSector: {
       "type": "string",
            "result":""
    },
    // Flag indicating if any user type is checked
    isAnyServiceChecked: false,
    // Currently selected assessment sector (text)
    currentSector: "",
  },
  reducers: {
    toggleService: (state, action) => {
        const { id } = action.payload;
        const service = state.services.data.find(item => item.id === id);
        if (service) {
            service.checked = !service.checked;
            state.services.data.forEach(item => item.checked = item.id === id);
        }
        // Check if any user type is currently checked
      state.isAnyServiceChecked = state.services.data.some(condition => condition.checked);
      const selectedService= state.services.data.find(userType => userType.checked === true);
      
       // Update the jsonService object with the selected user type data
       state.jsonService = {
        // Descriptive property name for clarity
          type: "text", // Data type of the selection
          result: selectedService.text, // The actual selected user type list
        
      };
    },
    toggleSector: (state, action) => {
      const { id } = action.payload; // Extract the ID from the payload

      // Find the sector item in the state based on the provided ID
      const sectorItem = state.sectors.find(item => item.id === id);

      state.services.data.forEach(item => item.checked = false);
      console.log(sectorItem);

      if (sectorItem) {
        // Toggle the isActive flag for the clicked sector
        sectorItem.checked = !sectorItem.checked;
        state.sectors.forEach(item => item.checked = item.id === id);

        if (sectorItem.checked) {
            console.log(sectorItem.text.replace(" ","_"));
          // Get the user IDs associated with the selected sector
          const ids = state.services[sectorItem.text.replace(" ","_")];

          // Update the current sector
          state.currentSector = sectorItem.text;

          // Update the isActive flag for user types based on their association with the selected sector
          state.services.data = state.services.data.map((item) => ({
            ...item,
            isActive: ids.includes(item.id),
          }));
        } else {
          // If the sector is deactivated, set all user types to inactive
          state.services.data = state.services.data.map((item) => ({
            ...item,
            isActive: false,
          }));
        }
      }

     
       // Check if any user type is currently checked
       state.isAnyServiceChecked = state.services.data.some(condition => condition.checked);
     

      const selectedSector = state.sectors.find(sector => sector.checked === true);
     // console.log(selectedSector.text);
      // Update the jsonService object with the selected user type data
      state.jsonSector = {
         // Descriptive property name for clarity
         type: "text", // Data type of the selection
         result: selectedSector.text, // The actual selected user type list
       
     };

     
    },
  },
});

// Export the toggleSector action creator
export const { toggleSector,toggleService } = sectorSlice.actions;

// Define selector functions to access specific parts of the state
export const selectSectors = state => state.sector.sectors;
export const selectServices = state => state.sector.services.data;
export const selectJsonService = state => state.sector.jsonService;
export const selectJsonSector = state => state.sector.jsonSector;
export const isForestChecked = state => state.sector.isForestChecked; // Potentially for future use
export const selectIsAnyServiceChecked = state => state.sector.isAnyServiceChecked; // Potentially for future use

// Export the reducer for use in the Redux store
export default sectorSlice.reducer;
