
import React from 'react';

const LocationIcon = () => {
  return (
    <img src='images/icons/location.png' height={'30px'} style={{marginTop:'-5px'}}/>
  );
};


export default LocationIcon;
