import React from 'react';
import './App.css';
import AppRouter from './AppRouter';
import './globals.css';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './reducers/store';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <ConfigProvider
  theme={{
    components: {
      Slider: {
        controlSize:20,
        controlHeight:20
        /* here is your component tokens */
      },
    },
  }}
>
<AppRouter />
</ConfigProvider>
</Provider>
      
     
    </div>
  );
}

export default App;
