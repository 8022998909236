import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
  name: 'countries',
  initialState: {
    
    data: [
      { name: 'Austria', code: 'AT' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czech Republic', code: 'CZ' },
      { name: 'Germany', code: 'DE' },
      { name: 'Denmark', code: 'DK' },
      { name: 'United Kingdom', code: 'UK' },
      { name: 'Spain', code: 'ES' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'Greece', code: 'GR' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Italy', code: 'IT' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Malta', code: 'MT' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Romania', code: 'RO' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Sweden', code: 'SE' }
    ],
    selectedCountry:'',
    jsonCountry:{
       "type": "string",
            "result":""
    }
  },
  reducers: {
    // Add reducer functions if needed
    setSelectedCountry: (state, action) => {
        const { value} = action.payload;
        state.selectedCountry = value;
        console.log(state.selectedCountry);
        const country = state.selectedCountry;
        state.jsonCountry =  {
          
          type: "text",
          result: country
        };
        console.log(state.selectedCountry);
      },
       getSelectedCountry: () => {
       // const country = state.selectedCountry;
        //const selectedCountry = terrainTypeItems.map(item => item.name ? 1 : 0);
       
       // state.jsonCountry = data;
      }
  },
 
});

// Export selector function to retrieve countries
export const {setSelectedCountry} = countrySlice.actions;
export const selectCountry = state => state.countries.selectedCountry;
export const {selectCountries} = state => state.countries.data;
export const {selectJSONCountry} = state => state.countries.jsonCountry;
//export const { setSelectedCountry } = countrySlice.actions;

export default countrySlice.reducer;
