import React from 'react';
import { Row, Col, Card, Typography } from 'antd';

const { Title } = Typography;

const LivestockHealthCanvas = () => {
  const data = {
    keyPartners: ['Internet service providers ISP', 'Technology providers', 'Application providers (data analytics)', 'Financial providers (e.g. banks)', 'Government and Regulatory bodies'],
    keyActivities: ['Provide health and wellbeing guidance & interventions for Livestock health'],
    valuePropositions: ['Enhance well fare of livestock', 'Livestock Record Keeping'],
    customerRelationships: ['Personal assistance', 'Co-creation (tailor-made)'],
    customerSegments: ['Research & Academia', 'SMEs (e.g. processors, retailers)'],
    channels: ['Social media channels', 'Website'],
    keyResources: ['Physical resources (cameras, microphones)', 'Human resources (software developers, technical support)'],
    costStructure: ['Fixed costs for Infrastructure and maintenance', 'Operational costs'],
    revenueStreams: ['Selling farming products (B2B/B2C)', 'Increased profitability via increased production', 'Increased profitability via cost reductions'],
  };

  return (
    <div>
      <Title level={2}>Livestock Health </Title>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="Key Partners">
            <ul>
              {data.keyPartners.map((partner, index) => (
                <li key={index}>{partner}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Key Activities">
            <ul>
              {data.keyActivities.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Value Propositions">
            <ul>
              {data.valuePropositions.map((proposition, index) => (
                <li key={index}>{proposition}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Customer Relationships">
            <ul>
              {data.customerRelationships.map((relationship, index) => (
                <li key={index}>{relationship}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Customer Segments">
            <ul>
              {data.customerSegments.map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Channels">
            <ul>
              {data.channels.map((channel, index) => (
                <li key={index}>{channel}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Key Resources">
            <ul>
              {data.keyResources.map((resource, index) => (
                <li key={index}>{resource}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Cost Structure">
            <ul>
              {data.costStructure.map((cost, index) => (
                <li key={index}>{cost}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Revenue Streams">
            <ul>
              {data.revenueStreams.map((stream, index) => (
                <li key={index}>{stream}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LivestockHealthCanvas;
