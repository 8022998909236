import React from 'react';
import { Card, Row } from 'antd';
import ServiceItem from '../Items/ServiceItem';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const QuestionCard = ({ questionEntry, handleChoice }) => {
  return (
    <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
      <div>
        <div
          style={{
            color: "#00678A",
            fontSize: "20px",
            fontWeight: "700",
            marginBottom: "40px",
          }}
        >
          {questionEntry.text}
        </div>

        <Row>
        {questionEntry.choices.map((item) => (
  <ServiceItem
    isActive={true} // You can customize this based on your logic
    key={uuidv4()} 
    checked={item.checked}
    text={item.text}
    onChange={(e) => handleChoice(item.id, e)}
  />
))}
        </Row>
        <Row></Row>
      </div>
    </Card>
  );
};
QuestionCard.propTypes = {
    questionEntry: PropTypes.any.isRequired,
    handleChoice: PropTypes.any.isRequired,
   
  };
export default QuestionCard;
