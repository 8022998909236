import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import SectorIcon from "../../components/Icons/SectorIcon";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";
import SectorTitleButton from "../../components/Button/SectorTitleButton";
import ServiceSelectionIcon from "../../components/Icons/ServiceSelection";
import SectorSelectionIcon from "../../components/Icons/SectorSelectionIcon";
import SectorItem from "../../components/Items/SectorItem";
import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAnyServiceChecked, selectSectors, selectServices, toggleSector, toggleService } from "../../reducers/sectorSlice";

const gridStyle = {
  width: "100%",
  textAlign: "center",
  //display:'flex',
  //justifyContent:'center',
};
export default function SectorServiceScreen() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);


  const dispatch = useDispatch();
  const sectors = useSelector(selectSectors);
  const services = useSelector(selectServices);
  const isAnyServiceChecked = useSelector(selectIsAnyServiceChecked);





  const handleItemClick = (id) => {

    dispatch(toggleSector({ id }));

  };


  const handleServiceItemClick = (id) => {
    

    dispatch(toggleService({ id }));

  };


  const handleConfirmButtonClick = () => {
    localStorage.setItem("step02", true);

    navigate(paths.Location_DETAILS.INDEX);
  }

  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <TitleButton
            icon={<SectorIcon size={"40px"} />}
            buttonText="Sector and service selection"
            isActive={true}
          />
        }
      >
        <Row style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={10}>
            <Card
              title={
                <SectorTitleButton
                  icon={<SectorSelectionIcon />}
                  buttonText="Sector  Selection"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle}>
                <div

                >
                  <Row >
                    {sectors.map((item) => (
                      <SectorItem
                        isActive={item.isActive}
                        key={item.id}
                        icon={item.icon} // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleItemClick(item.id)}
                      />
                    ))}
                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
          <Col span={14}>
            <Card
              justify="center" align="middle"
              title={
                <SectorTitleButton
                  icon={<ServiceSelectionIcon />}
                  buttonText="Service  Selection"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle} >
                <div
                //style={{ justifyContent: "center", alignContent: "center" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12} justify="center" align="middle">
                      {services.slice(0, Math.ceil(services.length / 2)).map((item) => (
                        <SectorItem
                          key={item.id}
                          isActive={item.isActive}
                          icon={item.icon} // Replace with your icon
                          text={item.text}
                          checked={item.checked}
                          onChange={() => handleServiceItemClick(item.id)}
                        />
                      ))}
                    </Col>
                    <Col span={12} justify="center" align="middle">
                      {services.slice(Math.ceil(services.length / 2)).map((item) => (
                        <SectorItem
                          key={item.id}
                          isActive={item.isActive}
                          icon={item.icon} // Replace with your icon
                          text={item.text}
                          checked={item.checked}
                          onChange={() => handleServiceItemClick(item.id)}
                        />
                      ))}
                    </Col>



                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
        </Row>
        <ConfirmButton isActive={isAnyServiceChecked} handleClick={() => handleConfirmButtonClick()} />
      </AppContent>
    </HomeLayout>
  );
}
