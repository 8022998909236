import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const ViewSwitcher = ({ onViewChange,view }) => {
  const [activeView, setActiveView] = useState(view??'normal');

  const handleViewChange = (view) => {
    setActiveView(view);
    onViewChange(view);
  };

  return (
    <div>
      <Button
        type={activeView === 'normal' ? 'primary' : 'default'}
        onClick={() => handleViewChange('normal')}
        style={{ marginRight: '0px' }}
      >
        Normal View
      </Button>
      <Button
        type={activeView === 'expert' ? 'primary' : 'default'}
        onClick={() => handleViewChange('expert')}
      >
        Expert View
      </Button>
    </div>
  );
};
ViewSwitcher.propTypes = {
    onViewChange: PropTypes.func.isRequired,
    view: PropTypes.string
  };
  

export default ViewSwitcher;
