import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';

// Define marks for the slider
const marks = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
};

/**
 * CustomSlider Component
 *
 * This component renders a slider with customizable marks and styling. 
 * It accepts a single prop 'name' which is displayed above the slider.
 *
 * Props:
 *  - name (string): The label to be displayed above the slider. This prop is required.
 */
const CustomSlider = ({ name }) => (
  <div style={{ width: 300, margin: '0 auto', textAlign: 'center' }}>
    {/* Label displayed above the slider */}
    <div style={{ marginBottom: '50px' }}>{name}:</div>

    {/* Slider component from Ant Design */}
    <Slider
      min={1}
      max={5}
      marks={marks}
      defaultValue={3}
      tooltipVisible
      tooltipPlacement="top"
      railStyle={{ backgroundColor: '#D3D3D3', height: 10 }}
      trackStyle={{ backgroundColor: '#00A676', height: 10 }}
      handleStyle={{
        borderColor: '#00A676',
        height: 20, // Total handle height
        width: 15, // Handle width
        marginLeft: -2.5, // Center handle
        marginTop: -5, // Adjust vertical alignment
        backgroundColor: '#FFF',
      }}
    />
  </div>
);

// Prop validation
CustomSlider.propTypes = {
  name: PropTypes.string.isRequired, // Name prop is required and must be a string
};

export default CustomSlider;
