import React from 'react';
import TerrainTypeButton from "../../components/Button/TerrainTypeButton";
const ImpactActionRow = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', }}>
      <TerrainTypeButton
        width={'100px'}
        height={'50px'}
        color={'#008D6B'}
        isActive={true}
        key={1}
        buttonText={"Area"}
      />
      <TerrainTypeButton
        width={'60px'}
        height={'50px'}
        color={'#002C3C61'}
        isActive={true}
        textColor={'#000000'}
        key={2}
        buttonText={"A"}
      />
      
      
    </div>
  );
};

export default ImpactActionRow;
