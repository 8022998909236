import React,{ useState } from "react";
import { Layout,Button, Modal, Input } from "antd";
import { Content } from "antd/es/layout/layout";
import PropTypes from "prop-types";
import HomeHeader from "../Headers/HomeHeader";
import ReportButton from "../Button/ReportButton";

const { TextArea } = Input;

export default function HomeLayout({ children }) {

    const [modalVisible, setModalVisible] = useState(false);

    const handleReportButtonClick = () => {
      setModalVisible(true);
    };
  
    const handleModalCancel = () => {
      setModalVisible(false);
    };
  
    const handleReportSubmit = () => {
      // Handle the submission logic here
      // You may want to send the report to your server or perform any other action
      setModalVisible(false);
    };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        background: "#00A27B",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Content
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HomeHeader />

        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children}
        </Content>
      </Content>

      {/* Modal for Reporting */}
      <Modal
      
          title="Report a Problem"
          visible={modalVisible}
          onCancel={handleModalCancel}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleReportSubmit}>
              Submit
            </Button>,
          ]}
          style={{ marginLeft: "auto"}}
        >
          <TextArea
            placeholder="Please describe the nature of the problem."
            autoSize={{ minRows: 6, maxRows: 10 }}
          />
        </Modal>

      <ReportButton handleClick={handleReportButtonClick}/>
    </Layout>
  );

  //write props validation here
}
HomeLayout.propTypes = {
  children: PropTypes.element,
};
