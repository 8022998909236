import React from "react";

import PropTypes from "prop-types";
import { Button } from "antd";

const ConfirmButton = ({handleClick,isActive,isLoading}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Your other header content */}
      <div style={{ marginLeft: "auto" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginTop:'10px',
          minHeight:'40px'
        }}
      >
        <Button loading={isLoading} type="primary"  onClick={handleClick} style={{minHeight:'60px',backgroundColor:isActive?'black':'rgba(0, 103, 138, 0.70)'}} disabled={!isActive??true}>
        <span style={{ color: "#FFF", padding: "5px",fontSize:'15px',fontWeight:'700' }}>Confirm Selection</span>
        </Button>
      </div>
    </div>
  );
};

ConfirmButton.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func
};

export default ConfirmButton;
