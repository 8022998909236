import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Col, Card, Collapse, Row } from "antd";
import ImpactActionRow from "../../components/Items/ImpactActionRow";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import TitleV2Button from "../../components/Button/TitleV2Button";
import ActionButton from "../../components/Button/ActionButton";
import { LeftCircleFilled } from "@ant-design/icons";
import JsonTreeView from "../../components/Viewers/JsonTreeView";
import INCDATARESPONSE from "../../utility/jsonINCDATA";


export default function SubResultTechno() {
  const navigate = useNavigate();
  const { Panel } = Collapse;

  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

 
  const handleSaveButtonClick = () => {
    // Your save button click logic here
    console.log("Save button clicked!");
    navigate(-1);
  };
  

  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ActionButton
            buttonText="Back"
            icon={<LeftCircleFilled style={{ fontSize: '16px', color: '#fff' }} />}
           onClick={handleSaveButtonClick}
          />
            <TitleV2Button
              icon={<EvaluateIcon size={"40px"} />}
              buttonText="Results: "
              secondText="Techno-economic assessment"
              isActive={true}
            />
            <div style={{ marginTop: "20px", display: "flex",
              justifyContent:"space-between",
              alignItems: "space-between",
              flexDirection: "row", }}>
           
              <ImpactActionRow />
            </div>
          </div>
        }
      >
        
        <Row
          style={{ width: "80vw", marginTop: "20px",minHeight:'40vh' }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
      <Panel header="INC API RESPONSE DATA: JSON VISUALIZATION" key="1">
        <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
          <div>
          <JsonTreeView jsonData={JSON.parse(INCDATARESPONSE())} />
         
          </div>
        </Card>
      </Panel>
    </Collapse>
          </Col>
         
        
        </Row>

        
      </AppContent>
    </HomeLayout>
  );
}
