import React from 'react';

const LoadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 33 34" fill="none">
    <path d="M20.625 0H4.125C1.85625 0 0.0206255 1.53 0.0206255 3.4L0 30.6C0 32.47 1.83562 34 4.10437 34H28.875C31.1437 34 33 32.47 33 30.6V10.2L20.625 0ZM28.875 30.6H4.125V3.4H18.5625V11.9H28.875V30.6ZM8.25 22.117L11.1581 24.514L14.4375 21.828V28.9H18.5625V21.828L21.8419 24.531L24.75 22.117L16.5206 15.3L8.25 22.117Z" fill="white"/>
  </svg>
  );
};

export default LoadIcon;
