import React from "react";

import PropTypes from "prop-types";
import { Button } from "antd";
import {
  MessageOutlined
} from "@ant-design/icons";

const FeedBackButton = ({handleClick}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" ,marginTop:'10px'}}>
      {/* Your other header content */}
      <div style={{ marginLeft: "auto" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Button type="primary" icon={<MessageOutlined  />} onClick={handleClick} style={{height:'40px'}}>
        <span style={{ color: "#fff", padding: "5px" ,fontWeight:'700'}}>Provide Feedback</span>
            </Button>
       
      </div>
    </div>
  );
};

FeedBackButton.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func
};

export default FeedBackButton;
