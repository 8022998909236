import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import MenuButton from "../../components/Button/MenuButton";
import SectorIcon from "../../components/Icons/SectorIcon";
import LocationIcon from "../../components/Icons/Location";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import paths from "../../routes/route";

export default function HomeScreen() {
  // Initializing the useNavigate hook from react-router-dom
  const navigate = useNavigate();

  // State variables to keep track of step02 and step03
  const [step02, setStep02] = useState(false);
  const [step03, setStep03] = useState(false);

  useEffect(() => {
    // Get the policyAccepted value from localStorage
    const policyAccepted = localStorage.getItem("policyAccepted");
    // Get the step02 value from localStorage
    const step02 = localStorage.getItem("step02");
    // Update the state variables with the values from localStorage
    setStep02(step02);
    setStep03(localStorage.getItem("step02"));
    console.log(step02);

    // If policies are not accepted, redirect to the root path
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  // Function to handle the click event on the Location Details button
  const handleLocationButtonClick = () => {
    navigate(paths.Location_DETAILS.INDEX);
  };

  // Function to handle the click event on the Service Requirements button
  const handleServiceButtonClick = () => {
    navigate(paths.SERVICE.NORMAL_VIEW);
  };

  return (
    <HomeLayout>
      <AppContent>
        {/* MenuButton component for Sector and Service Selection */}
        <MenuButton
          icon={<SectorIcon />}
          buttonText="Sector and service selection"
          isActive={true}
          handleClick={() => navigate(paths.SECTOR_SEVICES.STEP02)}
        />

        {/* MenuButton component for Location Details */}
        <MenuButton
          isActive={step02 != null && step02 ? true : false}
          icon={<LocationIcon />}
          buttonText="Location Details"
          handleClick={() => handleLocationButtonClick()}
        />

        {/* MenuButton component for Service Requirements */}
        <MenuButton
          icon={<ServiceIcon />}
          buttonText="Service Requirements"
          isActive={step03 != null && step03 ? true : false}
          handleClick={() => handleServiceButtonClick()}
        />

        {/* MenuButton component for Evaluate your case */}
        <MenuButton
          icon={<EvaluateIcon />}
          buttonText="Evaluate your case"
          height={"120px"}
        />
      </AppContent>
    </HomeLayout>
  );
}