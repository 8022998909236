// store.js
import { configureStore } from '@reduxjs/toolkit';
import weatherReducer from './weatherSlice'; // Import your reducer(s) here
import terrainReducer from './terrainSlice';
import countryReducer from './countrySlice';
import questionReducer from './questionSlice';
import areaReducer from './areaSlice';
import vegetationHeightReducer from './vegetationSlice';
import levelReducer from './levelSlice';
import sectorReducer from './sectorSlice';

const rootReducer = {
  weather: weatherReducer, 
  terrain: terrainReducer,
  countries: countryReducer,
  question : questionReducer,
  area: areaReducer,
  vegetation: vegetationHeightReducer,
  level: levelReducer,
  sector : sectorReducer
  // Add other reducers here
};
const store = configureStore({
  reducer: rootReducer,
});

export default store;