import { useSelector } from 'react-redux';
import { selectJsonVegetationHeight } from '../reducers/vegetationSlice';
import { selectJsonTerrain } from '../reducers/terrainSlice';
import { selectJsonWeather } from '../reducers/weatherSlice';
import { mergeJSONObjects } from './helper_data';


const jsonObject = () => {
  const JSONCountry = useSelector((state) => state.countries.jsonCountry);
  const JSONAreaSize = useSelector((state) =>  state.area.jsonAreaSize);
  const JSONVegetationHeight = useSelector(selectJsonVegetationHeight);
  const JSONTerrain = useSelector(selectJsonTerrain);
  const JSONWeather = useSelector(selectJsonWeather);

  const JSONSector = useSelector((state) => state.sector.jsonSector);
  const JSONService = useSelector((state) => state.sector.jsonService);
  const JSONUserType = useSelector((state) => state.level.jsonUserType);
  const JSONLevel = useSelector((state) => state.level.jsonLevel);
  const JSONData = useSelector((state) => state.question.apiBody);




 
    const data=  {
        sector: JSONSector,
        service: JSONService,
        user_type_selection: JSONUserType,
        level_of_assessment: JSONLevel,
        location: JSONCountry,
        Area: JSONAreaSize,
        Vegetation_height: JSONVegetationHeight,
        Terrain: JSONTerrain,
        Weather: JSONWeather
      };

      const body_request =  mergeJSONObjects(data,JSONData);

      localStorage.setItem('iccs_body_request',JSON.stringify(body_request));

      return body_request;
};

export default jsonObject;

