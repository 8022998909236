import { message } from "antd";

export const postSolutionsAnalysis = async () => {
    const URL = '/api1/solutionsanalysis';
    
    try {
        // Retrieve the access token
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found. Please log in first.');
        }

        // Retrieve the ICCS response data from localStorage
        const iccsResponseData = getJsonFromLocalStorage('iccs_response');
        if (!iccsResponseData) {
            throw new Error('No ICCS response data found in localStorage.');
        }

        // Make the POST request
        const response = await fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(iccsResponseData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Solutions Analysis response:', responseData);

        // Optionally, store the response in localStorage
        storeJsonToLocalStorage('solutionsAnalysisResponse', responseData);

        return true;

    } catch (error) {
        console.error('Error posting to Solutions Analysis:', error);
        message.error("Error posting to Solutions Analysis");
        return false;
    }
};

// Function to store JSON data in localStorage
export const storeJsonToLocalStorage = (key, jsonData) => {
    try {
      const jsonString = JSON.stringify(jsonData);
      localStorage.setItem(key, jsonString);
      console.log(`Data stored in localStorage with key: ${key}`);
    } catch (error) {
      console.error('Error storing data in localStorage:', error);
    }
  };
  
  // Function to retrieve JSON data from localStorage as an object
  export const getJsonFromLocalStorage = (key) => {
    try {
      const jsonString = localStorage.getItem(key);
      if (jsonString === null) {
        console.log(`No data found in localStorage for key: ${key}`);
        return null;
      }
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Error retrieving data from localStorage:', error);
      return null;
    }
  };