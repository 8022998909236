import React from "react";
import PropTypes from 'prop-types';

const SectorIcon = ({size}) => {

    const handleContextMenu = (e) => {
        e.preventDefault();
        // Your custom logic when the context menu is triggered on the image
      };
  return (
    <img
      src="images/icons/sector.svg"
      height={size??"30px"}
      style={{ marginTop: "-5px" }}
      onContextMenu={handleContextMenu}
    />
  );
};

SectorIcon.propTypes = {
    size: PropTypes.string,
    
  };

export default SectorIcon;
